import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/bootstrap-vue'

import NProgress from 'vue-nprogress'
import Notifications from 'vue-notification'

import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'


Vue.filter('numFormat', numFormat(numeral))
Vue.filter('currencyFilter', (v) => 'Ks ' + v)

Vue.use(NProgress, {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
})
// APIs: see https://github.com/rstacruz/nprogress

Vue.use(Notifications)
Vue.use(VueMeta)
Vue.use(VueSocialSharing)

const nprogress = new NProgress()

import './assets/scss/main.scss'
import i18n from './plugins/i18n'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-BV6EYL59QX" }
}, router);

import './registerServiceWorker'

new Vue({
  router,
  nprogress,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
